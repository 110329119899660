<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Shipping Home" />
      <form @submit.prevent="getShippingList">
        <b-row class="mb-4 align-items-end">
          <b-colxx
            md="6"
            sm="12">
            <m-text-input
              id="search-request"
              label="Search"
              :value="searchKeyword"
              :alternative="false"
              :group="false"
              :readonly="false"
              name="Search"
              placeholder="Search by contact name or request number"
              @update-value="
                (input) => {
                  searchKeyword = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="4"
            sm="12">
            <b-button
              variant="info"
              class="ml-1"
              @click="getShippingList()"
              >Search</b-button
            >
            <b-button
              variant="primary"
              @click="clearSearchList()"
              >Clear</b-button
            >
          </b-colxx>
          <b-colxx
            md="2"
            sm="12">
            <b-form-group
              id="hide-closed-fieldset"
              label="Hide Closed"
              label-for="hide-closed-yn"
              class="field-label-form-group mt-2">
              <b-form-checkbox
                id="hide-closed-yn"
                v-model="hideClosedYN"
                @change="getShippingList()"
                ></b-form-checkbox>
            </b-form-group>
          </b-colxx>
        </b-row>
      </form>

      <b-row class="my-4">
        <b-colxx
          md="12"
          sm="12">
          <b-table
            id="shipping-table"
            responsive
            head-variant="dark"
            :items="shippingList"
            :fields="shipping_list_fields">
            <template #cell(action)="row">
              <i
                class="fas fa-eye text-default icon-pointer"
                @click="goShippingDetail(row.item)" />
            </template>
          </b-table>
          <b-pagination
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            :number-of-pages="pageTotal"
            size="sm"
            class="my-0"
            aria-controls="shipping-table"
            @input="getShippingList(current_page)"></b-pagination>
        </b-colxx>
      </b-row>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  export default {
    name: "ShippingHome",
    components: {},

    data() {
      return {
        shippingList: [],
        shipping_list_fields: [
          {
            key: "action",
            label: "",
          },
          {
            key: "customer_name",
            label: "Customer",
          },
          {
            key: "contact_name",
            label: "Contact",
          },
          {
            key: "service_request_number",
            label: "Request #",
          },
          {
            key: "shipping_status_name",
            label: "Status",
          },
          {
            key: "shipment_tracking_number",
            label: "Tracking #",
          },
          {
            key: "shipper_name",
            label: "Shipper",
          },
        ],

        current_page: 1,
        per_page: 10,
        total_rows: null,
        prev_page_link: "",
        next_page_link: "",

        searchKeyword: "",
        hideClosedYN: true,
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader", "serviceRequestTypeCodes"]),

      pageTotal() {
        var page_total = 1
        page_total = Math.ceil(this.total_rows / this.per_page)
        return page_total
      },
    },

    created() {},

    async mounted() {
      this.$store.dispatch("common/showLoader", true)
      document.title = "Shipping Home"
      await this.getShippingList(this.current_page)
    },

    methods: {
      async getShippingList() {
        this.$store.dispatch("common/showLoader", true)
        document.title = "Shipping Home"
        await django_session
          .get("erp/service/ship/list/", {
            params: {
              page: this.current_page,
              search_keyword: this.searchKeyword,
              hide_closed: this.hideClosedYN,
            },
          })
          .then((response) => {
            this.shippingList = response.data.results
            this.total_rows = response.data.count
            this.prev_page_link = response.data.prev_page_link
            this.next_page_link = response.data.next_page_link
            this.$store.dispatch("common/showLoader", false)
          })
          .catch((error) => {
            this.$store.dispatch("common/showLoader", false)
          })
      },

      goShippingDetail(item) {
        this.$store.dispatch("service/setShipmentId", item.shipment_id)
        this.$router.push({ name: "ShippingDetail", params: { id: item.shipment_id } })
      },

      clearSearchList() {
        this.searchKeyword = ""
        this.current_page = 1
        this.hideClosedYN = false
        this.getShippingList()
      },
    },
  }
</script>
