<template>
  <div
    v-if="!showLoader"
    class="mb-4">
    <m-page-title :title="pageTitle" />

    <b-alert
      id="save-request-toast"
      v-model="showSaveRequestAlert"
      class="w-100"
      dismissible
      :title="saveAlertTitle"
      :variant="saveAlertVariant">
      {{ saveAlertMessage }}
    </b-alert>

    <div class="full-height-row card">
      <div v-if="newRequestYN && canEditFields">
        <b-row class="align-items-end mb-4">
          <b-colxx
            md="4"
            sm="10">
            <form
              @submit.prevent="searchForContact()"
              class="p-0">
              <m-text-input
                id="search-contact"
                label="Search for Contact"
                :value="searchContactName"
                :alternative="false"
                :group="false"
                name="Search Contact Name"
                @update-value="
                  (input) => {
                    searchContactName = input
                  }
                " />
            </form>
          </b-colxx>
          <b-colxx
            md="2"
            sm="2">
            <b-button
              variant="success"
              @click="searchForContact()"
              >Contact Search</b-button
            >
          </b-colxx>
          <b-colxx
            md="4"
            sm="10">
            <form
              @submit.prevent="searchForCustomer()"
              class="p-0">
              <m-text-input
                id="search-contact"
                label="Customer Search"
                :value="searchCustomerName"
                :alternative="false"
                :group="false"
                name="Search Customer Name"
                @update-value="
                  (input) => {
                    searchCustomerName = input
                  }
                " />
            </form>
          </b-colxx>
          <b-colxx
            md="2"
            sm="2">
            <b-button
              variant="success"
              @click="searchForCustomer()"
              >Customer Search</b-button
            >
          </b-colxx>
        </b-row>
      </div>

      <b-form-row>
        <b-colxx
          md="3"
          sm="12">
          <b-form-group
            id="request-status-fieldset"
            label="Status *"
            label-for="request-status"
            class="field-label-form-group mt-2">
            <b-form-select
              id="request-status"
              v-model="requestDetail.service_request_status"
              text-field="type_label"
              value-field="entity_type_code_id"
              :disabled="requestIsClosed || !canEditFields"
              :options="requestStatusCodes"></b-form-select>
          </b-form-group>
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <b-form-group
            id="request-type-fieldset"
            label="Request Type *"
            label-for="request-type"
            class="field-label-form-group mt-2">
            <b-form-select
              id="request-type"
              v-model="requestDetail.service_request_type"
              text-field="type_label"
              value-field="entity_type_code_id"
              :disabled="requestIsClosed || !canEditFields"
              :options="serviceRequestTypeCodes"></b-form-select>
          </b-form-group>
        </b-colxx>
      </b-form-row>

      <b-form-row class="align-items-start">
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="first-name"
            label="First Name"
            :rules="{ required: true, max: 50 }"
            :value="requestDetail.first_name"
            :alternative="false"
            :group="false"
            name="First Name"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.first_name = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="last-name"
            label="Last Name"
            :rules="{ required: true, max: 50 }"
            :value="requestDetail.last_name"
            :alternative="false"
            :group="false"
            name="Last Name"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.last_name = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="customer-name"
            label="Customer Name"
            :value="requestDetail.customer_name"
            :alternative="false"
            :group="false"
            name="City"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.customer_name = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="phone"
            label="Phone"
            type="phone"
            :rules="{ required: true, max: 50 }"
            :value="requestDetail.phone"
            :alternative="false"
            :group="false"
            name="Phone"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.phone = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="email"
            label="Email"
            :rules="{ required: true, email: true }"
            :value="requestDetail.email_address"
            :alternative="false"
            :group="false"
            name="Email"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.email_address = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="address-1"
            label="Address 1"
            :rules="{ required: true, max: 100 }"
            :value="requestDetail.address_1"
            :alternative="false"
            :group="false"
            name="Address 1"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.address_1 = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="address-2"
            label="Address 2"
            :rules="{ max: 100 }"
            :value="requestDetail.address_2"
            :alternative="false"
            :group="false"
            name="Address 2"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.address_2 = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="city"
            label="City"
            :rules="{ required: true, max: 75 }"
            :value="requestDetail.city"
            :alternative="false"
            :group="false"
            name="City"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.city = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="2"
          sm="12">
          <b-form-group
            id="prov-type-fieldset"
            label="Province *"
            label-for="prov-select"
            class="field-label-form-group mt-2">
            <b-form-select
              id="prov-select"
              v-model="requestDetail.province"
              :disabled="requestIsClosed || !canEditFields"
              :options="province"></b-form-select>
          </b-form-group>
        </b-colxx>
        <b-colxx
          md="2"
          sm="12">
          <m-text-input
            id="postalcode"
            label="Postal Code"
            :rules="{ required: true, max: 7 }"
            :value="requestDetail.postal_code"
            :alternative="false"
            :group="false"
            name="Postal Code"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.postal_code = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="rga-no"
            label="RGA #"
            :value="requestDetail.rga_number"
            :alternative="false"
            :group="false"
            :disabled="true"
            name="RGA Number" />
        </b-colxx>
        <b-colxx
          md="2"
          sm="12">
          <b-form-group
            id="add-cc-yn-fieldset"
            label="Add Credit Card"
            label-for="add-credit-card-yn"
            class="field-label-form-group mt-2">
            <b-form-checkbox
              id="add-credit-card-yn"
              :disabled="requestIsClosed || !canEditFields"
              v-model="requireCreditCardInfoYN"></b-form-checkbox>
          </b-form-group>
        </b-colxx>
        <b-colxx
          md="2"
          sm="12">
          <b-form-group
            id="proof-of-purchase-fieldset"
            label="Proof of Purchase/Upload File"
            label-for="proof-of-purchase"
            class="field-label-form-group mt-2 mb-0">
            <b-form-file
              id="upload-pop"
              v-model="requestDetail.proof_of_purchase_file"
              :state="Boolean(requestDetail.proof_of_purchase_file)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              :disabled="requestIsClosed || !canEditFields || newRequestYN"
              @change="uploadProofOfPurchase($event)"></b-form-file>
          </b-form-group>
          <div class="file-upload-text" v-if="newRequestYN">*You must save the new service request before you can upload a document.</div>
          <m-spinny-message
            v-if="waitForUpload"
            message="Uploading document. Please wait." />
        </b-colxx>
        <b-colxx
          md="2"
          sm="12">
          <div v-if="requestDetail.proof_of_purchase_url != ''">
            <b-badge
              pill
              variant="primary"
              class="text-white mt-5"
              ><span
                class="i fas fa-paperclip text-white mr-2 icon-pointer"
                @click="viewProofOfPurchase()" />Proof of Purchase<span
                class="i fas fa-circle-xmark text-red ml-2 icon-pointer"
                @click="deleteProofOfPurchase()"
            /></b-badge>
          </div>
        </b-colxx>
      </b-form-row>

      <b-form-row
        v-if="requireCreditCardInfoYN"
        class="align-items-center">
        <b-colxx
          md="4"
          sm="12">
          <m-text-input
            id="creditcard"
            label="Credit Card #"
            :rules="{ required: requireCreditCardInfoYN, min: 14, max: 16 }"
            :value="requestDetail.cc_number"
            :alternative="false"
            :group="false"
            name="Credit Card"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.cc_number = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="ccexp"
            label="CC Exp (MMYY)"
            :rules="{ required: requireCreditCardInfoYN, max: 4 }"
            :value="requestDetail.cc_expiry"
            :alternative="false"
            :group="false"
            name="Credit Card Expiry Date"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="
              (input) => {
                requestDetail.cc_expiry = input
              }
            " />
        </b-colxx>

        <b-colxx
          v-if="requestDetail.service_request_id != null && requestDetail.proof_of_purchase_url == ''"
          md="1"
          sm="12">
          <b-button
            v-if="!waitForUpload"
            class="btn btn-success"
            :disabled="requestDetail.proof_of_purchase_file == null"
            @click="saveProofOfPurchase()">
            Upload</b-button
          >
        </b-colxx>
      </b-form-row>

      <b-alert
        v-if="documentUploadAlert"
        :variant="documentUploadAlertVariant"
        dismissible
        show
        >{{ documentUploadAlertMessage }}</b-alert
      >

      <b-row class="mt-4">
        <b-colxx
          md="12"
          sm="12">
          <b-card no-body>
            <b-card-header class="bg-primary"
              ><span class="text-white my-2">Ship</span>
              <b-button
                v-if="!requestIsClosed && canEditFields"
                variant="warning"
                class="float-right ml-2"
                size="sm"
                @click="openAddProduct('ship')"
                >Add Product</b-button
              >
              <b-button
                v-if="!requestIsClosed && canEditFields"
                variant="light"
                class="float-right"
                size="sm"
                @click="openAddPumpToShipping()"
                >Add Pump</b-button
              >
            </b-card-header>
            <b-card-body>
              <b-row class="mb-2">
                <b-colxx
                  md="6"
                  sm="12">
                  <m-text-input
                    id="shipper_name_ship"
                    label="Shipper Name"
                    :value="requestDetail.ship_shipper_name"
                    :alternative="false"
                    :group="false"
                    :readonly="true"
                    name="Shipper Name" />
                </b-colxx>
                <b-colxx
                  md="6"
                  sm="12">
                  <m-text-input
                    id="ship-track-ship"
                    label="Tracking Number"
                    :value="requestDetail.ship_shipment_tracking_number"
                    :alternative="false"
                    :group="false"
                    :readonly="true"
                    name="Tracking Number" />
                </b-colxx>
              </b-row>
              <b-alert
                v-model="showShipmentLineItemDeletedAlert"
                variant="danger"
                dismissible>
                {{ shipmentLineItemDeletedAlertMessage }}
              </b-alert>
              <b-table
                id="shipment-lines-table"
                ref="__shipmentLineDetail"
                responsive
                head-variant="dark"
                :bordered="true"
                :items="requestDetail.shipment_line_items"
                :fields="ship_detail_line_item_fields">
                <template #cell(action)="row">
                  <div v-if="!row.item.line_is_shipped_yn">
                    <i
                      v-if="canEditFields"
                      class="fas fa-trash text-danger icon-pointer"
                      @click="deleteShipmentLineItem(row.item, row.index)" />
                  </div>
                </template>
                <template #cell(order_quantity)="row">
                  <b-form-input
                    :id="'soq' + row.item.id"
                    :disabled="row.item.line_is_shipped_yn || !canEditFields"
                    type="number"
                    v-model="row.item.order_quantity" />
                </template>
                <template #cell(ship_quantity)="row">
                  <b-form-input
                    :id="'sq' + row.item.id"
                    :readonly="true"
                    :disabled="true"
                    type="number"
                    v-model="row.item.ship_quantity" />
                </template>
              </b-table>
              <b-row class="my-4">
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-area
                    id="shipping-note"
                    :value="requestDetail.shipping_notes"
                    name="Shipping Notes"
                    :rows="7"
                    :rules="{ max: 4000 }"
                    label="Shipping Notes"
                    :alternative="false"
                    :group="false"
                    :readonly="requestIsClosed || !canEditFields"
                    @update-value="(input) => (requestDetail.shipping_notes = input)" />
                </b-colxx>
              </b-row>
            </b-card-body>
          </b-card>
        </b-colxx>
      </b-row>

      <b-row class="mt-4">
        <b-colxx
          md="12"
          sm="12">
          <b-card no-body>
            <b-card-header class="bg-warning">
              <span class="text-white my-2">Receive</span>
              <b-button
                v-if="!requestIsClosed && canEditFields"
                variant="info"
                size="sm"
                class="float-right ml-2"
                @click="openAddProduct('receive')"
                >Add Product</b-button
              >
              <b-button
                v-if="!requestIsClosed && canEditFields"
                variant="success"
                class="float-right"
                size="sm"
                @click="openAddPump('receive')"
                >Add Pump</b-button
              >
            </b-card-header>
            <b-card-body>
              <b-row class="mb-2">
                <b-colxx
                  md="6"
                  sm="12">
                  <m-text-input
                    id="shipper_name_ship"
                    label="Shipper Name"
                    :value="requestDetail.receive_shipper_name"
                    :alternative="false"
                    :group="false"
                    :readonly="true"
                    name="Shipper Name" />
                </b-colxx>
                <b-colxx
                  md="6"
                  sm="12">
                  <m-text-input
                    id="ship-track-ship"
                    label="Tracking Number"
                    :value="requestDetail.receive_shipment_tracking_number"
                    :alternative="false"
                    :group="false"
                    :readonly="true"
                    name="Tracking Number" />
                </b-colxx>
              </b-row>
              <b-alert
                v-model="showReceivingLineItemDeletedAlert"
                variant="danger"
                dismissible>
                {{ receivingLineItemDeletedAlertMessage }}
              </b-alert>
              <b-table
                id="request-detail-receive-lines-table"
                ref="__receiveLineDetail"
                responsive
                head-variant="dark"
                :bordered="true"
                :items="requestDetail.receive_line_items"
                :fields="receive_detail_line_item_fields">
                <template #cell(action)="row">
                  <div v-if="!row.item.line_is_received_yn">
                    <i
                      v-if="canEditFields"
                      class="fas fa-trash text-danger icon-pointer"
                      @click="deleteReceivingLineItem(row.item, row.index)" />
                  </div>
                </template>
                <template #cell(order_quantity)="row">
                  <b-form-input
                    :id="'roq' + row.item.id"
                    :disabled="row.item.line_is_received_yn || !canEditFields"
                    type="number"
                    v-model="row.item.order_quantity" />
                </template>
                <template #cell(ship_quantity)="row">
                  <b-form-input
                    :id="'rq' + row.item.id"
                    :readonly="true"
                    :disabled="true"
                    type="number"
                    v-model="row.item.ship_quantity" />
                </template>
              </b-table>
              <b-row class="my-4">
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-area
                    id="receiving-note"
                    :value="requestDetail.receiving_notes"
                    name="Receiving Notes"
                    :rows="7"
                    :rules="{ max: 4000 }"
                    label="Receiving Notes"
                    :alternative="false"
                    :group="false"
                    :readonly="requestIsClosed || !canEditFields"
                    @update-value="(input) => (requestDetail.receiving_notes = input)" />
                </b-colxx>
              </b-row>
            </b-card-body>
          </b-card>
        </b-colxx>
      </b-row>

      <b-row>
        <b-colxx
          md="12"
          sm="12">
          <b-button
            v-if="showGeneratePackingSlipButton"
            variant="info"
            @click="generateCustomerPackingSlip()"
            >Generate Customer Packing Slip</b-button
          >
        </b-colxx>
      </b-row>

      <b-form-row class="mt-2 align-items-center">
        <b-colxx
          v-if="newRequestYN"
          md="12"
          sm="12">
          <m-text-area
            id="note"
            :value="requestDetail.note"
            name="Notes"
            :rows="7"
            :rules="{ max: 4000 }"
            label="Notes"
            :alternative="false"
            :group="false"
            :disabled="requestIsClosed || !canEditFields"
            @update-value="(input) => (requestDetail.note = input)" />
        </b-colxx>
      </b-form-row>

      <b-form-row
        v-if="!newRequestYN"
        class="mt-4">
        <b-colxx
          md="12"
          sm="12">
        </b-colxx>
        <b-table
          id="service-request-notes-table"
          responsive
          head-variant="dark"
          :items="requestDetail.notes"
          :fields="service_request_notes_fields">
          <template #head(add_note)>
            <!-- <div v-b-popover.hover.right="'New Note'"> -->
            <i
              v-if="canEditFields"
              class="fas fa-square-plus icon-pointer text-primary text-white"
              @click="
                () => {
                  showNoteModal = true
                }
              " />
            <!-- </div> -->
          </template>
          <template #cell(created_at)="row">
            {{ new Date(row.item.created_at).toLocaleDateString() }}
          </template>
        </b-table>
      </b-form-row>
    </div>
    <b-row class="mt-2">
      <b-colxx
        v-if="canEditFields"
        md="12"
        sm="12">
        <b-button
          variant="success"
          type="submit"
          :disabled="disableSaveButton"
          @click="saveNewRequest"
          >{{ saveButtonLabel }}</b-button
        >
      </b-colxx>
    </b-row>

    <!-- View Proof of Purchase Modal -->
    <div id="view-pop-modal">
      <b-modal
        id="search-contact-list-modal"
        v-model="viewProofOfPurchaseModal"
        title="Preview"
        centered
        ok-only
        scrollable
        header-bg-variant="info"
        header-text-variant="white"
        size="md">
        <img :src="requestDetail.proof_of_purchase_url" />
      </b-modal>
    </div>

    <!-- New Note Modal -->
    <div id="create-new-note">
      <b-modal
        id="search-contact-list-modal"
        v-model="showNoteModal"
        title="Add a Note"
        centered
        ok-only
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="md">
        <m-spinny-message
          v-if="requestNoteIsSaving"
          message="Note is saving." />
        <b-alert
          v-model="showSaveNoteAlert"
          :variant="saveNoteAlertVariant"
          class="w-100"
          dismissible
          >{{ saveNoteAlertMessage }}</b-alert
        >
        <m-text-area
          v-if="!requestNoteIsSaving"
          id="note"
          :value="addNoteContent"
          name="Notes"
          :rows="7"
          :rules="{ max: 4000 }"
          label="Notes"
          :alternative="false"
          :group="false"
          @update-value="(input) => (addNoteContent = input)" />
        <template #modal-footer="{}">
          <b-button
            v-if="!requestNoteSaveComplete"
            variant="info"
            :disabled="requestNoteIsSaving"
            @click="saveNote()">
            Save
          </b-button>
          <b-button
            variant="warning"
            :disabled="requestNoteIsSaving"
            @click="closeNoteModal()"
            >Close</b-button
          >
        </template>
      </b-modal>
    </div>

    <!-- Search Contact List Modal -->
    <div id="search-contact-list">
      <b-modal
        id="search-contact-list-modal"
        v-model="showContactList"
        title="Contact Search Results"
        centered
        ok-only
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="lg">
        <b-table
          id="contact-search-result-table"
          v-if="searchContactResults.length > 0"
          ref="__contactSearchTable"
          responsive
          head-variant="dark"
          :items="searchContactResults"
          :fields="search_contact_fields">
          <template #cell(select)="row">
            <i
              class="fas fa-circle-check text-default icon-pointer"
              @click="selectContact(row.item)" />
          </template>
        </b-table>
        <b-table
          id="company-search-result-table"
          v-if="searchCustomerResults.length > 0"
          ref="__companySearchTable"
          responsive
          head-variant="dark"
          :items="searchCustomerResults"
          :fields="search_customer_fields">
          <template #cell(select)="row">
            <i
              class="fas fa-circle-check text-default icon-pointer"
              @click="selectCustomer(row.item)" />
          </template>
        </b-table>
      </b-modal>
    </div>

    <!-- Select Pump Type Modal -->
    <div id="select-pump-type-modal">
      <b-modal
        id="pump-type-modal"
        v-model="showPumpTypeModal"
        title="Select Pump Type to Ship"
        centered
        ok-only
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="lg">
        <b-row>
          <b-colxx
            md="12"
            sm="12">
            <b-table
              id="pump-search-result-table"
              ref="__pumpTypeTable"
              responsive
              head-variant="dark"
              :items="pumpTypes"
              :fields="pump_type_fields">
              <template #cell(select)="row">
                <i
                  class="fas fa-circle-plus text-default icon-pointer"
                  @click="selectPumpType(row.item)" />
              </template>
            </b-table>
          </b-colxx>
        </b-row>
      </b-modal>
    </div>

    <!-- Search Pump List Modal -->
    <div id="search-pump-list">
      <b-modal
        id="search-pump-list-modal"
        v-model="showPumpSelectModal"
        title="Search Pumps"
        centered
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="lg">
        <m-spinny-message
          v-if="searchPumpIsLoading"
          message="Searching for pumps. Please wait." />
        <b-row
          v-if="!searchPumpIsLoading"
          class="align-items-center mb-4">
          <b-colxx
            md="6"
            sm="12">
            <b-form
              id="navbar-search-main"
              @submit.prevent="
                () => {
                  searchPumps()
                }
              ">
              <b-form-group class="mb-0">
                <b-input-group class="">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                  <b-form-input
                    v-model.trim="searchPumpKeyword"
                    placeholder="Search for a pump by serial number"
                    type="text">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-form>
          </b-colxx>
          <b-colxx
            md="6"
            sm="12">
            <b-button
              variant="success"
              size="sm"
              @click="
                () => {
                  searchPumps()
                }
              "
              >Search</b-button
            >
            <b-button
              variant="info"
              size="sm"
              @click="
                () => {
                  searchPumpKeyword = ''
                  searchPumpResults = []
                }
              "
              >Clear</b-button
            >
            <b-button
              variant="light"
              size="sm"
              @click="
                () => {
                  addSingleUsePumpYN = !addSingleUsePumpYN
                }
              "
              >{{ singleUsePumpOptionLabel }}</b-button
            >
          </b-colxx>
        </b-row>
        <b-row v-if="!addSingleUsePumpYN">
          <b-colxx
            md="12"
            sm="12">
            <b-table
              id="pump-search-result-table"
              ref="__pumpSearchTable"
              responsive
              head-variant="dark"
              :items="filteredSearchPumpResults"
              :fields="search_pump_fields">
              <template #cell(select)="row">
                <i
                  class="fas fa-circle-plus text-default icon-pointer"
                  @click="selectPump(row.item)" />
              </template>
              <template #cell(is_leased_yn)="row">
                <i
                  class="fas"
                  :class="row.item.is_leased_yn ? 'fa-circle-check text-success' : 'fa-circle-xmark'" />
              </template>
            </b-table>
          </b-colxx>
        </b-row>
        <b-form-row
          v-if="addSingleUsePumpYN"
          class="align-items-center">
          <b-colxx
            md="8"
            sm="8">
            <m-text-input
              id="single-use-pump-serial-no"
              label="Serial Number"
              :rules="{ required: true, max: 75 }"
              :value="singleUsePumpSerialNumber"
              :alternative="false"
              :group="false"
              name="Serial Number"
              @update-value="
                (input) => {
                  singleUsePumpSerialNumber = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="3"
            sm="3">
            <b-button
              variant="success"
              size="sm"
              @click="addSingleUsePumpToReceiving()"
              >Save Pump</b-button
            >
          </b-colxx>
        </b-form-row>
        <template #modal-footer="{}">
          <div v-if="addSingleUsePumpYN"></div>
          <div v-if="!addSingleUsePumpYN">
            <b-button
              size="md"
              class="mr-2"
              variant="info"
              @click="
                () => {
                  showPumpSelectModal = false
                }
              ">
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>

    <!-- Search Product List Modal -->
    <div id="search-product-list">
      <b-modal
        id="search-product-list-modal"
        v-model="showProductSelectModal"
        title="Search Products"
        centered
        ok-only
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="lg">
        <m-spinny-message
          v-if="searchProductIsLoading"
          message="Searching for products. Please wait." />
        <b-row
          v-if="!searchProductIsLoading"
          class="align-items-center mb-4">
          <b-colxx
            md="8"
            sm="8">
            <b-form
              id="navbar-search-main"
              @submit.prevent="
                () => {
                  searchProducts()
                }
              ">
              <b-form-group class="mb-0">
                <b-input-group class="">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                  <b-form-input
                    v-model.trim="searchProductKeyword"
                    placeholder="Search for a product by name or product id"
                    type="text">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-form>
          </b-colxx>
          <b-colxx
            md="4"
            sm="4">
            <b-button
              variant="success"
              size="sm"
              @click="
                () => {
                  searchProducts()
                }
              "
              >Search</b-button
            >
            <b-button
              variant="info"
              size="sm"
              @click="
                () => {
                  searchProductKeyword = ''
                  searchProductResults = []
                }
              "
              >Clear</b-button
            >
          </b-colxx>
        </b-row>
        <b-row>
          <b-colxx
            md="12"
            sm="12">
            <b-table
              id="product-search-result-table"
              ref="__productSearchTable"
              responsive
              head-variant="dark"
              :items="searchProductResults"
              :fields="search_product_fields">
              <template #cell(select)="row">
                <i
                  class="fas fa-circle-plus text-default icon-pointer"
                  @click="selectProduct(row.item)" />
              </template>
            </b-table>
          </b-colxx>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import { province } from "@/constants/province"
  import { rgaObj } from "@/constants/objects/rga"
  import { baseDomain } from "@/constants/common"

  export default {
    name: "RequestDetail",
    components: {},

    data() {
      return {
        province,
        rgaObj,
        createContactYN: true,
        // isCustomerContact: false,
        requireCreditCardInfoYN: false,
        searchContactName: "",
        searchContactResults: [],
        search_contact_fields: [
          {
            label: "Select",
            key: "select",
          },
          {
            label: "Name",
            key: "full_name",
          },
          {
            label: "Customer",
            key: "customer_name",
          },
          {
            label: "Email",
            key: "email",
          },
          {
            label: "Phone",
            key: "phone",
          },
        ],
        showContactList: false,

        searchCustomerName: "",
        searchCustomerResults: [],
        search_customer_fields: [
          {
            label: "Select",
            key: "select",
          },
          {
            label: "Name",
            key: "customer_name",
          },
          {
            label: "City",
            key: "city",
          },
        ],

        requestDetail: {},
        service_request_notes_fields: [
          {
            key: "add_note",
            label: "",
            thStyle: { width: "5%" },
          },
          {
            key: "created_at",
            label: "Date",
          },
          {
            key: "note",
            label: "Note",
          },
          {
            key: "created_by_name",
            label: "Author",
          },
        ],
        showNoteModal: false,
        addNoteContent: "",

        showSaveNoteAlert: false,
        saveNoteAlertVariant: "",
        saveNoteAlertMessage: "",

        requestDetailChanged: false,
        requestDetailNotYetSaved: false,
        isExistingContact: false,

        requestNoteIsSaving: false,
        requestNoteSaveComplete: false,

        getPumpType: "",
        showPumpTypeModal: false,
        pump_type_fields: [
          {
            label: "Select",
            key: "select",
          },
          {
            label: "Name",
            key: "name",
          },
        ],

        searchPumpIsLoading: false,
        searchProductIsLoading: false,

        serviceLineItemType: "",
        showProductSelectModal: false,
        searchProductKeyword: "",
        searchProductResults: [],
        search_product_fields: [
          {
            label: "",
            key: "select",
          },
          {
            label: "Name",
            key: "sname",
          },
          {
            label: "UPC",
            key: "spartcode",
          },
          {
            label: "Supplier",
            key: "supplier_name",
          },
          {
            label: "Item #",
            key: "sku",
          },
        ],

        searchPumpKeyword: "",
        searchPumpResults: [],
        search_pump_fields: [
          {
            label: "",
            key: "select",
          },
          {
            label: "Pump Type",
            key: "pump_type_name",
          },
          {
            label: "Serial #",
            key: "serial_no",
          },
          {
            label: "Leased",
            key: "is_leased_yn",
          },
          {
            label: "Purchase Date",
            key: "purchase_date",
          },
        ],
        showPumpSelectModal: false,

        addSingleUsePumpYN: false,
        singleUsePumpSerialNumber: "",

        editLine: null,
        editLineIndex: null,
        ship_detail_line_item_fields: [
          {
            label: "",
            key: "action",
          },
          {
            label: "Order Qty",
            key: "order_quantity",
          },
          {
            label: "Stock Unit",
            key: "stock_unit",
          },
          {
            label: "Shipped Qty",
            key: "ship_quantity",
          },
          {
            label: "Name",
            key: "product_description",
          },
        ],
        receive_detail_line_item_fields: [
          {
            label: "",
            key: "action",
          },
          {
            label: "Return Qty",
            key: "order_quantity",
          },
          {
            label: "Stock Unit",
            key: "stock_unit",
          },
          {
            label: "Received Qty",
            key: "ship_quantity",
          },
          {
            label: "Name",
            key: "product_description",
          },
          {
            label: "Serial No.",
            key: "receiving_pump_serial_number",
          },
        ],

        waitForUpload: false,
        newFile: {
          file: null,
          name: "",
          type: "",
          size: "",
          entity_type_class_name: "servicerequest",
          owning_entity_id: null,
          created_by: null,
          updated_by: null,
        },
        documentUploadAlert: false,
        documentUploadAlertMessage: "",
        documentUploadAlertVariant: "",
        viewProofOfPurchaseModal: false,

        saveInProgress: false,
        showSaveRequestAlert: false,
        saveAlertTitle: "",
        saveAlertMessage: "",
        saveAlertVariant: "",

        showShipmentLineItemDeletedAlert: false,
        shipmentLineItemDeletedAlertMessage: "",
        showReceivingLineItemDeletedAlert: false,
        receivingLineItemDeletedAlertMessage: "",
      }
    },

    watch: {
      requestDetail: {
        handler(newVal, oldVal) {
          if (!this.showLoader) {
            // console.log(newVal, oldVal)
            this.requestDetailChanged = true
            this.requestDetailNotYetSaved = true
          }
        },
        deep: true,
      },
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader", "serviceRequestTypeCodes", "requestStatusCodes"]),
      ...mapGetters("service", ["newRequestYN", "serviceRequestId"]),
      ...mapGetters("pump", ["pumpTypes"]),

      pageTitle() {
        if (this.newRequestYN) {
          return "New Request"
        } else {
          return "Request #: " + this.requestDetail.rga_number
        }
      },

      saveButtonLabel() {
        if (this.saveInProgress) {
          return "Saving..."
        }
        return "Save"
      },

      canEditFields() {
        if (
          this.permissions.includes("CanCreateServiceRequest") ||
          this.permissions.includes("CanEditServiceRequest")
        ) {
          return true
        }
        return false
      },

      requestIsClosed() {
        if (this.requestDetail.service_request_status === null) {
          return false
        }
        if (
          this.requestDetail.service_request_status ===
          this.requestStatusCodes.find((item) => item.type_code === "CLOSED").entity_type_code_id
        ) {
          return true
        }
        return false
      },

      disableSaveButton() {
        if (
          this.requestDetail.service_request_type === null ||
          this.requestDetail.first_name === "" ||
          this.requestDetail.last_name === null ||
          this.requestDetail.phone === "" ||
          this.requestDetail.email_address === "" ||
          this.requestDetail.address_1 === "" ||
          this.requestDetail.city === "" ||
          this.requestDetail.province === "" ||
          this.requestDetail.postal_code === ""
        ) {
          return true
        }
        if (this.saveInProgress) {
          return true
        }
        return false
      },

      singleUsePumpOptionLabel() {
        if (this.addSingleUsePumpYN) {
          return "Cancel Add Single User Pump"
        } else {
          return "Add Single User Pump"
        }
      },

      filteredSearchPumpResults() {
        let filteredResults = this.searchPumpResults
        filteredResults = filteredResults.filter(
          ({ pump_id: value1 }) =>
            !this.requestDetail.receive_line_items.some(({ pump_id: value2 }) => value1 === value2)
        )
        return filteredResults
      },

      showGeneratePackingSlipButton() {
        if (this.newRequestYN) {
          return false
        }
        if (!this.newRequestYN) {
          if (this.requestDetail.receive_line_items !== undefined && this.requestDetail.receive_line_items.length > 0) {
            return true
          }
        }
        return false
      },
    },

    created() {},

    beforeRouteLeave(to, from, next) {
      if (this.requestDetailChanged && this.requestDetailNotYetSaved) {
        if (confirm("You have unsaved changes. Are you sure you want to leave?")) {
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    },

    async mounted() {
      this.$store.dispatch("common/showLoader", true)
      if (this.newRequestYN) {
        this.requestDetail = JSON.parse(JSON.stringify(rgaObj))
        this.requestDetail.service_request_status = this.requestStatusCodes.find(
          (item) => item.type_code === "OPEN"
        ).entity_type_code_id
      }
      if (!this.newRequestYN) {
        await django_session
          .get("erp/service/rga/" + this.serviceRequestId + "/")
          .then((response) => {
            this.requestDetail = response.data
            const appendLineProps = {
              existing_line_yn: true,
            }
            for (var a = 0; a < this.requestDetail.receive_line_items.length; a++) {
              Object.assign(this.requestDetail.receive_line_items[a], appendLineProps)
            }
            for (var b = 0; b < this.requestDetail.shipment_line_items.length; b++) {
              Object.assign(this.requestDetail.shipment_line_items[b], appendLineProps)
            }
          })
          .catch((error) => {})
        if (this.requestDetail.cc_expiry !== "") {
          this.requireCreditCardInfoYN = true
        }
      }

      document.title = "Service Request Detail"
      this.$store.dispatch("common/showLoader", false)
    },

    methods: {
      async searchForContact() {
        this.$store.dispatch("common/showLoader", true)
        await django_session
          .get("erp/customer/contact/search/", {
            params: {
              page_size: 0,
              keyword: this.searchContactName,
            },
          })
          .then((response) => {
            // console.log(response.data)
            this.searchContactResults = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.$store.dispatch("common/showLoader", false)
        this.showContactList = true
      },

      async searchForCustomer() {
        this.$store.dispatch("common/showLoader", true)
        await django_session
          .get("erp/customer/search/", {
            params: {
              page_size: 0,
              keyword: this.searchCustomerName,
              hide_inactive_yn: true,
              hide_prospects_yn: true,
            },
          })
          .then((response) => {
            console.log("Return customer list")
            this.searchCustomerResults = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.showContactList = true
        this.$store.dispatch("common/showLoader", false)
      },

      async selectContact(item) {
        this.showContactList = false
        console.log(item)
        this.requestDetail.contact_id = item.contact_id
        this.requestDetail.customer_id = item.erp_customer_id
        this.requestDetail.customer_name = item.customer_name
        this.requestDetail.first_name = item.first_name
        this.requestDetail.last_name = item.last_name
        this.requestDetail.phone = item.phone
        this.requestDetail.email_address = item.email
        this.requestDetail.address_1 = item.address_1
        this.requestDetail.address_2 = item.address_2
        this.requestDetail.city = item.city
        this.requestDetail.province = item.province
        this.requestDetail.postal_code = item.postal_code
      },

      async selectCustomer(item) {
        this.showContactList = false
        console.log(item)
        this.requestDetail.customer_id = item.erp_customer_id
        this.requestDetail.customer_name = item.customer_name
        this.requestDetail.first_name = ""
        this.requestDetail.last_name = ""
        this.requestDetail.phone = item.phone
        this.requestDetail.email_address = ""
        this.requestDetail.address_1 = item.address_1
        this.requestDetail.address_2 = item.address_2
        if (this.requestDetail.address_2 === null) {
          this.requestDetail.address_2 = ""
        }
        this.requestDetail.city = item.city
        this.requestDetail.province = item.province
        this.requestDetail.postal_code = item.postal_code
      },

      async saveNote() {
        this.requestNoteSaveComplete = false
        this.requestNoteIsSaving = true
        await django_session
          .post("erp/service/note/create/", {
            note: this.addNoteContent,
            request: this.serviceRequestId,
            created_by: this.user.user_id,
            modified_by: this.user.user_id,
          })
          .then((response) => {
            this.requestDetail.notes.unshift(response.data)
            this.saveNoteAlertVariant = "success"
            this.saveNoteAlertMessage = "Note saved to service record."
          })
          .catch((error) => {
            console.log(error.response)
            this.saveNoteAlertVariant = "warning"
            this.saveNoteAlertMessage = "Error saving note."
          })
        this.requestNoteIsSaving = false
        this.requestNoteSaveComplete = true
        this.showSaveNoteAlert = true
      },

      closeNoteModal() {
        this.showNoteModal = false
        this.addNoteContent = ""
        this.requestNoteSaveComplete = false
        this.showSaveNoteAlert = false
        this.saveNoteAlertMessage = ""
        this.saveNoteAlertVariant = ""
      },

      openAddPumpToShipping() {
        this.showPumpTypeModal = true
      },

      openAddPump(shipOrReturn) {
        console.log("Get Pump", shipOrReturn)
        this.getPumpType = shipOrReturn
        this.searchPumpKeyword = ""
        this.searchPumpResults = []
        this.showPumpSelectModal = true
      },

      async searchPumps() {
        this.searchPumpIsLoading = true
        await django_session
          .get("erp/pump/list/", {
            params: {
              search_pump_keyword: this.searchPumpKeyword,
              page_size: 0,
              hide_inactive: true,
            },
          })
          .then((response) => {
            this.searchPumpResults = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.searchPumpIsLoading = false
      },

      openAddProduct(shipOrReturn) {
        console.log(shipOrReturn)
        this.serviceLineItemType = shipOrReturn
        this.searchProductKeyword = ""
        this.searchProductResults = []
        this.showProductSelectModal = true
      },

      async searchProducts() {
        this.searchProductIsLoading = true
        await django_session
          .get("sage/product-list/", {
            params: {
              page_size: 0,
              keyword: this.searchProductKeyword,
            },
          })
          .then((response) => {
            this.searchProductResults = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.searchProductIsLoading = false
      },

      selectProduct(item) {
        let addLineItemFields = {
          id: item.lid,
          ship_quantity: null,
          order_quantity: null,
          serial_number: "",
          product_description: item.sname,
          stock_unit: item.ssellunit,
          pump_id: null,
          existing_line_yn: false,
          line_item_is_pump_yn: false,
          shipping_pump: null,
          receiving_pump: null,
          shipping_line_id: null,
          receiving_line_id: null,
        }
        console.log(item)
        if (this.serviceLineItemType === "ship") {
          Object.assign(item, addLineItemFields)
          // item.product_description = item.sname
          this.requestDetail.shipment_line_items.push(item)
        }
        if (this.serviceLineItemType === "receive") {
          Object.assign(item, addLineItemFields)
          this.requestDetail.receive_line_items.push(item)
        }
        // filter selected product from search results
        this.searchProductResults = this.searchProductResults.filter((product) => product.lid !== item.lid)
      },

      selectPump(item) {
        let addLineItemFields = {
          id: item.pump_id,
          ship_quantity: null,
          order_quantity: null,
          sname: item.pump_type_name,
          product_description: item.pump_type_name,
          serial_number: item.serial_no,
          receiving_pump_serial_number: item.serial_no,
          receiving_pump: item.pump_id,
          order_quantity: 1,
          stock_unit: "Each",
          supplier_name: "",
          existing_line_yn: false,
          line_item_is_pump_yn: true,
          shipping_line_id: null,
          receiving_line_id: null,
        }
        console.log(item)
        if (this.getPumpType === "ship") {
          // item.pump_id = null
          Object.assign(item, addLineItemFields)
          this.requestDetail.shipment_line_items.push(item)
        }
        if (this.getPumpType === "receive") {
          Object.assign(item, addLineItemFields)
          // item.pump_id = item.pump_id
          this.requestDetail.receive_line_items.push(item)
        }
      },

      selectPumpType(item) {
        let addLineItemFields = {
          id: item.pump_type_id,
          ship_quantity: null,
          sname: item.name,
          product_description: item.name,
          serial_number: "",
          shipping_pump_serial_number: "",
          shipping_pump: null,
          order_quantity: 1,
          supplier_name: "",
          stock_unit: "Each",
          existing_line_yn: false,
          pump_id: null,
          line_item_is_pump_yn: true,
          shipping_line_id: null,
        }
        Object.assign(item, addLineItemFields)
        this.requestDetail.shipment_line_items.push(item)
      },

      addSingleUsePumpToReceiving() {
        let addLineItemFields = {
          id: null,
          ship_quantity: null,
          sname: "Single Use Pump",
          product_description: "Single Use Pump",
          serial_number: this.singleUsePumpSerialNumber,
          receiving_pump_serial_number: this.singleUsePumpSerialNumber,
          receiving_pump: null,
          order_quantity: 1,
          supplier_name: "",
          stock_unit: "Each",
          existing_line_yn: false,
          create_single_use_pump_yn: true,
          line_item_is_pump_yn: true,
          receiving_line_id: null,
        }
        // Object.assign(item, addLineItemFields)
        this.requestDetail.receive_line_items.push(addLineItemFields)
        this.addSingleUsePumpYN = false
        this.singleUsePumpSerialNumber = ""
      },

      uploadProofOfPurchase(event) {
        // https://github.com/bootstrap-vue/bootstrap-vue/issues/4134
        // The browse and drop events return different object structures.

        if (event.type === "drop") {
          var dropfile = event.dataTransfer.files[0]
          console.log(dropfile)
          this.newFile.name = dropfile.name
          this.newFile.size = dropfile.size
          this.newFile.type = dropfile.type
        }
        if (event.type === "change") {
          var file = document.getElementById("upload-pop")
          console.log(file.files[0])
          this.newFile.name = file.files[0].name
          this.newFile.size = file.files[0].size
          this.newFile.type = file.files[0].type
        }
      },

      deleteProofOfPurchase() {
        django_session
          .delete("erp/common/document/delete/" + this.requestDetail.proof_of_purchase_document_id + "/")
          .then(() => {
            this.requestDetail.proof_of_purchase_url = ""
          })
      },

      async saveProofOfPurchase() {
        this.waitForUpload = true
        let data = new FormData()
        data.append("file", this.requestDetail.proof_of_purchase_file)
        data.append("file_name", this.newFile.name)
        data.append("file_type", this.newFile.type)
        data.append("file_size", this.newFile.size)
        data.append("entity_type_class_name", this.newFile.entity_type_class_name)
        data.append("owning_entity_id", this.requestDetail.service_request_id)
        data.append("created_by", this.user.user_id)
        data.append("modified_by", this.user.user_id)

        await django_session
          .post("erp/common/document/create/", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data)
            this.documentUploadAlert = true
            this.documentUploadAlertMessage = "Document uploaded successfully."
            this.documentUploadAlertVariant = "success"
            this.requestDetail.proof_of_purchase_url = response.data.file
            this.requestDetail.proof_of_purchase_document_id = response.data.document_id
            ;(this.newFile.file = null), (this.newFile.name = ""), (this.newFile.type = ""), (this.newFile.size = "")
          })
          .catch((error) => {
            // console.log(error.response)
            this.documentUploadAlert = true
            this.documentUploadAlertMessage = "Error uploading document"
            this.documentUploadAlertVariant = "danger"
          })
        this.waitForUpload = false
      },

      viewProofOfPurchase() {
        console.log(this.requestDetail.proof_of_purchase_url)
        this.viewProofOfPurchaseModal = true
      },

      async deleteShipmentLineItem(item, index) {
        if (item.shipping_line_id !== null) {
          await django_session
            .delete("erp/service/shipment-line-item/delete/" + item.shipping_line_id + "/")
            .then(() => {
              this.requestDetail.shipment_line_items.splice(index, 1)
              this.shipmentLineItemDeletedAlertMessage = "Line item deleted."
            })
            .catch((error) => {
              console.log(error.response)
              this.shipmentLineItemDeletedAlertMessage = "Error deleting line item."
            })
        }
        if (item.shipping_line_id === null) {
          this.requestDetail.shipment_line_items.splice(index, 1)
          this.shipmentLineItemDeletedAlertMessage = "Line item deleted."
        }
        this.showShipmentLineItemDeletedAlert = true
      },

      async deleteReceivingLineItem(item, index) {
        if (item.receiving_line_id !== null) {
          await django_session
            .delete("erp/service/receiving-line-item/delete/" + item.receiving_line_id + "/")
            .then(() => {
              this.requestDetail.receive_line_items.splice(index, 1)
              this.receivingLineItemDeletedAlertMessage = "Line item deleted."
            })
            .catch((error) => {
              console.log(error.response)
              this.receivingLineItemDeletedAlertMessage = "Error deleting line item."
            })
        }
        if (item.receiving_line_id === null) {
          this.requestDetail.receive_line_items.splice(index, 1)
          this.receivingLineItemDeletedAlertMessage = "Line item deleted."
        }
        this.showReceivingLineItemDeletedAlert = true
      },

      async saveNewRequest() {
        this.saveInProgress = true

        if (!this.newRequestYN) {
          await django_session
            .patch("erp/service/rga/" + this.serviceRequestId + "/", {
              first_name: this.requestDetail.first_name,
              last_name: this.requestDetail.last_name,
              email_address: this.requestDetail.email_address,
              phone: this.requestDetail.phone,
              address_1: this.requestDetail.address_1,
              address_2: this.requestDetail.address_2,
              city: this.requestDetail.city,
              province: this.requestDetail.province,
              postal_code: this.requestDetail.postal_code,
              contact_id: this.requestDetail.contact_id,
              customer_id: this.requestDetail.customer_id,
              customer_name: this.requestDetail.customer_name,
              cc_number: this.requestDetail.cc_number,
              cc_expiry: this.requestDetail.cc_expiry,
              note: this.requestDetail.note,
              service_request_type: this.requestDetail.service_request_type,
              service_request_status: this.requestDetail.service_request_status,
              receive_line_items: this.requestDetail.receive_line_items,
              shipment_line_items: this.requestDetail.shipment_line_items,
              shipping_notes: this.requestDetail.shipping_notes,
              receiving_notes: this.requestDetail.receiving_notes,
              // reason_code: this.requestDetail.reason_code,
            })
            .then((response) => {
              console.log(response.data)
              this.requestDetail = response.data
              for (var a = 0; a < this.requestDetail.receive_line_items.length; a++) {
                this.requestDetail.receive_line_items[a].existing_line_yn = true
              }
              for (var b = 0; b < this.requestDetail.shipment_line_items.length; b++) {
                this.requestDetail.shipment_line_items[b].existing_line_yn = true
              }
              this.saveAlertTitle = "Save Complete"
              this.saveAlertMessage = "Service request record has been updated."
              this.saveAlertVariant = "success"
            })
            .catch((error) => {
              console.log(error.response)
              this.saveAlertTitle = "Save Error"
              this.saveAlertMessage = "There was a problem updating the service request."
              this.saveAlertVariant = "warning"
            })
        }

        if (this.newRequestYN) {
          await django_session
            .post("erp/service/rga/create/", {
              first_name: this.requestDetail.first_name,
              last_name: this.requestDetail.last_name,
              email_address: this.requestDetail.email_address,
              phone: this.requestDetail.phone,
              address_1: this.requestDetail.address_1,
              address_2: this.requestDetail.address_2,
              city: this.requestDetail.city,
              province: this.requestDetail.province,
              postal_code: this.requestDetail.postal_code,
              contact_id: this.requestDetail.contact_id,
              customer_id: this.requestDetail.customer_id,
              customer_name: this.requestDetail.customer_name,
              cc_number: this.requestDetail.cc_number,
              cc_expiry: this.requestDetail.cc_expiry,
              note: this.requestDetail.note,
              service_request_type: this.requestDetail.service_request_type,
              service_request_status: this.requestDetail.service_request_status,
              receive_line_items: this.requestDetail.receive_line_items,
              shipment_line_items: this.requestDetail.shipment_line_items,
              shipping_notes: this.requestDetail.shipping_notes,
              receiving_notes: this.requestDetail.receiving_notes,
            })
            .then((response) => {
              console.log(response.data)
              // this.requestDetail.rga_number = response.data.rga_number
              // this.requestDetail.service_request_id = response.data.service_request_id
              this.requestDetail = response.data
              for (var a = 0; a < this.requestDetail.receive_line_items.length; a++) {
                this.requestDetail.receive_line_items[a].existing_line_yn = true
              }
              for (var b = 0; b < this.requestDetail.shipment_line_items.length; b++) {
                this.requestDetail.shipment_line_items[b].existing_line_yn = true
              }
              this.$store.dispatch("service/setServiceRequestId", response.data.service_request_id)
              this.$store.commit("service/SET_NEW_REQUEST_FLAG", false)
              this.saveAlertMessage = "Service request record has been created."
              // this.saveAlertMessage =+ response.data.create_messages + "<br>"
              this.saveAlertTitle = "Save Complete"
              this.saveAlertVariant = "success"
            })
            .catch((error) => {
              console.log(error.response)
              this.saveAlertTitle = "Save Error"
              this.saveAlertMessage = error.response.data
              this.saveAlertVariant = "warning"
            })
        }
        this.requestDetailNotYetSaved = false
        this.showSaveRequestAlert = true
        this.saveInProgress = false
      },

      async generateCustomerPackingSlip() {
        await this.saveNewRequest()
        console.warn(baseDomain)
        window.open(baseDomain + "erp/service/customerPackingSlip?serviceRequestId=" + this.serviceRequestId, "_blank")
      },
    },
  }
</script>

<style lang="scss" scoped>
  .file-upload-text {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
</style>
