<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Shipment Detail" />
      <b-alert
        id="save-request-toast"
        v-model="showSaveRequestAlert"
        class="w-100"
        dismissible
        :title="saveAlertTitle"
        :variant="saveAlertVariant">
        {{ saveAlertMessage }}
      </b-alert>
      <div class="full-height-row card">
        <b-form-row class="align-items-start">
          <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="rga-no"
              label="RGA Number"
              :value="shipmentDetail.service_request_number"
              :alternative="false"
              :group="false"
              :disabled="true"
              :readonly="true"
              name="RGA Number" />
          </b-colxx>
          <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="contact-name"
              label="Contact Name"
              :rules="{ required: true, max: 50 }"
              :value="shipmentDetail.contact_name"
              :alternative="false"
              :group="false"
              :readonly="false"
              :disabled="!permissions.includes('CanEditShippingRecord')"
              name="Contact Name"
              @update-value="
                (input) => {
                  shipmentDetail.contact_name = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="customer-name"
              label="Customer Name"
              :rules="{ max: 50 }"
              :value="shipmentDetail.customer_name"
              :alternative="false"
              :group="false"
              :readonly="false"
              :disabled="!permissions.includes('CanEditShippingRecord')"
              name="Custome Namer"
              @update-value="
                (input) => {
                  shipmentDetail.customer_name = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="phone"
              label="Phone"
              type="phone"
              :rules="{ required: true, max: 50 }"
              :value="shipmentDetail.phone"
              :alternative="false"
              :group="false"
              name="Phone"
              :readonly="false"
              :disabled="!permissions.includes('CanEditShippingRecord')"
              @update-value="
                (input) => {
                  shipmentDetail.phone = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="email"
              label="Email"
              :rules="{ required: true, email: true }"
              :value="shipmentDetail.email_address"
              :alternative="false"
              :group="false"
              name="Email"
              :readonly="false"
              :disabled="!permissions.includes('CanEditShippingRecord')"
              @update-value="
                (input) => {
                  shipmentDetail.email_address = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="address-1"
              label="Address 1"
              :rules="{ required: true, max: 100 }"
              :value="shipmentDetail.address_1"
              :alternative="false"
              :group="false"
              name="Address 1"
              :readonly="false"
              :disabled="!permissions.includes('CanEditShippingRecord')"
              @update-value="
                (input) => {
                  shipmentDetail.address_1 = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="address-2"
              label="Address 2"
              :rules="{ max: 100 }"
              :value="shipmentDetail.address_2"
              :alternative="false"
              :group="false"
              name="Address 2"
              :readonly="false"
              :disabled="!permissions.includes('CanEditShippingRecord')"
              @update-value="
                (input) => {
                  shipmentDetail.address_2 = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="city"
              label="City"
              :rules="{ required: true, max: 75 }"
              :value="shipmentDetail.city"
              :alternative="false"
              :group="false"
              name="City"
              :readonly="false"
              :disabled="!permissions.includes('CanEditShippingRecord')"
              @update-value="
                (input) => {
                  shipmentDetail.city = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="2"
            sm="12">
            <b-form-group
              id="prov-type-fieldset"
              label="Province"
              label-for="prov-select"
              class="field-label-form-group mt-2">
              <b-form-select
                id="prov-select"
                v-model="shipmentDetail.province"
                :disabled="!permissions.includes('CanEditShippingRecord')"
                :options="province"></b-form-select>
            </b-form-group>
          </b-colxx>
          <b-colxx
            md="2"
            sm="12">
            <m-text-input
              id="postalcode"
              label="Postal Code"
              :rules="{ required: true, max: 7 }"
              :value="shipmentDetail.postal_code"
              :alternative="false"
              :group="false"
              name="Postal Code"
              :readonly="false"
              :disabled="!permissions.includes('CanEditShippingRecord')"
              @update-value="
                (input) => {
                  shipmentDetail.postal_code = input
                }
              " />
          </b-colxx>
        </b-form-row>
        <b-form-row>
          <b-colxx
            md="3"
            sm="12">
            <b-form-group
              id="request-status-fieldset"
              label="Status *"
              label-for="request-status"
              class="field-label-form-group mt-2">
              <b-form-select
                id="request-status"
                v-model="shipmentDetail.shipping_status"
                text-field="type_label"
                value-field="entity_type_code_id"
                :disabled="!permissions.includes('CanEditShippingRecord')"
                :options="requestStatusCodes"></b-form-select>
            </b-form-group>
          </b-colxx>
          <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="outbound-tracking"
              label="Outbound Tracking #"
              :value="shipmentDetail.shipment_tracking_number"
              :rules="{}"
              :alternative="false"
              :group="false"
              :disabled="!permissions.includes('CanEditShippingRecord')"
              name="Outbound Tracking #"
              @update-value="
                (input) => {
                  shipmentDetail.shipment_tracking_number = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="3"
            sm="12">
            <b-form-group
              id="request-status-fieldset"
              label="Shipper *"
              label-for="request-status"
              class="field-label-form-group mt-2">
              <b-form-select
                id="request-status"
                v-model="shipmentDetail.shipper"
                text-field="shipper_name"
                value-field="shipper_id"
                :disabled="!permissions.includes('CanEditShippingRecord')"
                :options="shipperList">
              </b-form-select>
            </b-form-group>
          </b-colxx>
        </b-form-row>
        <b-alert
          v-if="shipmentDetail.related_receive_lines_yn"
          show
          variant="warning"
          class="w-100">
          FYI. There are items that will be received as part of the service request. When generating the packing slips,
          the return packing slip will be generated as well.
        </b-alert>
        <b-row class="mt-4">
          <b-colxx
            md="12"
            sm="12">
            <b-table
              id="shipment-lines-table"
              ref="__shipmentLineDetail"
              responsive
              head-variant="dark"
              :bordered="true"
              :items="shipmentDetail.ship_line_items"
              :fields="ship_line_item_fields">
              <template #cell(edit)="row">
                <i
                  v-if="row.item.line_item_is_posted_yn"
                  class="fas fa-circle-check text-success" />
              </template>
              <template #cell(ship_quantity)="row">
                <b-form-input
                  :id="'sq' + row.index"
                  type="number"
                  :disabled="
                    row.item.line_item_is_posted_yn ||
                    row.item.line_item_is_pump_yn ||
                    !permissions.includes('CanEditShippingRecord')
                  "
                  v-model="row.item.ship_quantity" />
              </template>
              <template #cell(serial_no)="row">
                <b-form-input
                  :id="'serialno' + row.index"
                  :readonly="true"
                  v-model="row.item.serial_number" />
                <b-button
                  v-if="
                    row.item.line_item_is_pump_yn &&
                    !row.item.line_item_is_posted_yn &&
                    permissions.includes('CanEditShippingRecord')
                  "
                  variant="success"
                  size="sm"
                  @click="openAddPump(row.item, row.index)"
                  >Select Pump</b-button
                >
              </template>
            </b-table>
          </b-colxx>
        </b-row>
        <b-row class="my-4">
          <b-colxx
            md="12"
            sm="12">
            <m-text-area
              id="shipping-note"
              :value="shipmentDetail.shipping_notes"
              name="Shipping Notes"
              :rows="7"
              :rules="{ max: 4000 }"
              label="Shipping Notes"
              :alternative="false"
              :group="false"
              :disabled="!permissions.includes('CanEditShippingRecord')"
              @update-value="(input) => (shipmentDetail.shipping_notes = input)" />
          </b-colxx>
        </b-row>
        <div id="service-request-notes">
          <b-form-row>
            <b-colxx
              md="12"
              sm="12">
              <b-table
                id="service-request-notes-table"
                responsive
                head-variant="dark"
                :items="shipmentDetail.service_request_notes"
                :fields="service_request_notes_fields">
                <template #head(add_note)>
                  <i
                    v-if="permissions.includes('CanEditShippingRecord')"
                    class="fas fa-square-plus icon-pointer text-primary text-white"
                    @click="
                      () => {
                        showNoteModal = true
                      }
                    " />
                </template>
                <template #cell(created_at)="row">
                  {{ new Date(row.item.created_at).toLocaleDateString() }}
                </template>
              </b-table>
            </b-colxx>
          </b-form-row>
        </div>
      </div>

      <b-row class="my-4">
        <b-colxx
          md="12"
          sm="12">
          <b-button
            v-if="permissions.includes('CanEditShippingRecord')"
            variant="success"
            class="mr-2"
            :disabled="false"
            @click="saveShipmentRecord()"
            >Save</b-button
          >
          <b-button
            variant="info"
            class="mr-2"
            @click="generatePackingSlip()"
            >Generate Packing Slips</b-button
          >
        </b-colxx>
      </b-row>
    </div>

    <!-- Search Pump List Modal -->
    <div id="search-pump-list">
      <b-modal
        id="search-pump-list-modal"
        v-model="showPumpSelectModal"
        title="Search Pumps"
        centered
        ok-only
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="lg">
        <b-row class="align-items-center mb-4">
          <b-colxx
            md="8"
            sm="8">
            <b-form
              id="navbar-search-main"
              @submit.prevent="
                () => {
                  searchPumps()
                }
              ">
              <b-form-group class="mb-0">
                <b-input-group class="">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                  <b-form-input
                    v-model.trim="searchPumpKeyword"
                    placeholder="Search for a pump by serial number"
                    type="text">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-form>
          </b-colxx>
          <b-colxx
            md="4"
            sm="4">
            <b-button
              variant="success"
              size="sm"
              @click="
                () => {
                  searchPumps()
                }
              "
              >Search</b-button
            >
            <b-button
              variant="info"
              size="sm"
              @click="
                () => {
                  searchPumpKeyword = ''
                  searchPumpResults = []
                }
              "
              >Clear</b-button
            >
          </b-colxx>
        </b-row>
        <b-row>
          <b-colxx
            md="12"
            sm="12">
            <b-table
              id="pump-search-result-table"
              ref="__pumpSearchTable"
              responsive
              head-variant="dark"
              :items="filterSearchPumpResults"
              :fields="search_pump_fields">
              <template #cell(select)="row">
                <i
                  class="fas fa-circle-plus text-default icon-pointer"
                  @click="selectPump(row.item)" />
              </template>
              <template #cell(is_leased_yn)="row">
                <i
                  class="fas"
                  :class="row.item.is_leased_yn ? 'fa-circle-check text-success' : 'fa-circle-xmark'" />
              </template>
            </b-table>
          </b-colxx>
        </b-row>
      </b-modal>
    </div>

    <!-- New Note Modal -->
    <div id="create-new-note">
      <b-modal
        id="search-contact-list-modal"
        v-model="showNoteModal"
        title="Add a Note"
        centered
        ok-only
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="md">
        <m-spinny-message
          v-if="requestNoteIsSaving"
          message="Note is saving." />
        <b-alert
          v-model="showSaveNoteAlert"
          :variant="saveNoteAlertVariant"
          class="w-100"
          dismissible
          >{{ saveNoteAlertMessage }}</b-alert
        >
        <m-text-area
          v-if="!requestNoteIsSaving"
          id="note"
          :value="addNoteContent"
          name="Notes"
          :rows="7"
          :rules="{ max: 4000 }"
          label="Notes"
          :alternative="false"
          :group="false"
          @update-value="(input) => (addNoteContent = input)" />
        <template #modal-footer="{}">
          <b-button
            v-if="!requestNoteSaveComplete"
            variant="info"
            :disabled="requestNoteIsSaving"
            @click="saveNote()">
            Save
          </b-button>
          <b-button
            variant="warning"
            :disabled="requestNoteIsSaving"
            @click="closeNoteModal()"
            >Close</b-button
          >
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import { province } from "@/constants/province"
  import { baseDomain } from "@/constants/common"

  export default {
    name: "ShippingDetail",
    components: {},

    data() {
      return {
        province,
        shipmentDetail: {},

        editLine: null,
        editLineIndex: null,
        shipperList: [],

        ship_line_item_fields: [
          {
            label: "",
            key: "edit",
          },
          {
            label: "Order Qty",
            key: "order_quantity",
          },
          {
            label: "Order Qty",
            key: "order_quantity",
          },
          {
            label: "Ship Qty",
            key: "ship_quantity",
          },
          {
            label: "Name",
            key: "product_description",
          },
          {
            label: "Serial #",
            key: "serial_no",
          },
        ],

        // editLine: null,

        service_request_notes_fields: [
          {
            key: "add_note",
            label: "",
            thStyle: { width: "5%" },
          },
          {
            key: "created_at",
            label: "Date",
          },
          {
            key: "note",
            label: "Note",
          },
          {
            key: "created_by_name",
            label: "Author",
          },
        ],

        shipLineIndex: null,
        shipLineItemId: null,
        searchPumpKeyword: "",
        searchPumpResults: [],
        search_pump_fields: [
          {
            label: "",
            key: "select",
          },
          {
            label: "Pump Type",
            key: "pump_type_name",
          },
          {
            label: "Serial #",
            key: "serial_no",
          },
          {
            label: "Leased",
            key: "is_leased_yn",
          },
          {
            label: "Purchase Date",
            key: "purchase_date",
          },
        ],
        showPumpSelectModal: false,
        showNoteModal: false,
        requestNoteIsSaving: false,
        requestNoteSaveComplete: false,
        addNoteContent: "",
        showSaveNoteAlert: false,
        saveNoteAlertVariant: "",
        saveNoteAlertMessage: "",

        saveAlertMessage: "",
        saveAlertVariant: "",
        saveAlertTitle: "",
        showSaveRequestAlert: false,
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader", "serviceRequestTypeCodes", "requestStatusCodes"]),
      ...mapGetters("service", ["shipmentId"]),

      filterSearchPumpResults() {
        // Filter the search results to only show pumps that are not already selected in any of the ship_line_items
        let filteredResults = this.searchPumpResults.filter((pump) => {
          let found = false
          this.shipmentDetail.ship_line_items.forEach((lineItem) => {
            if (lineItem.shipping_pump === pump.pump_id) {
              found = true
            }
          })
          return !found
        })
        return filteredResults
      },
    },

    created() {},

    async mounted() {
      this.getShipmentDetail()
      await django_session
        .get("erp/product/shipper/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.shipperList = response.data
        })
        .catch((error) => {
          console.log(error.response)
        })
    },

    methods: {
      async getShipmentDetail() {
        this.$store.dispatch("common/showLoader", true)
        await django_session
          .get("erp/service/ship/" + this.shipmentId + "/")
          .then((response) => {
            this.shipmentDetail = response.data
          })
          .catch((error) => {
            console.log(error.response)
          })

        // Check shipment lines to see if there are multiple pumps on any line
        // If there are, then we need to split the line into multiple lines
        // with an order quantity and ship quantity of 1 for each line
        // set the first line item where line_item_is_pump_yn = true to have order quantity of 1 and ship quantity of 1

        this.shipmentDetail.ship_line_items.forEach((lineItem, index) => {
          console.log("Line Item", lineItem)
          if (lineItem.line_item_is_pump_yn && lineItem.order_quantity > 1) {
            let pumpCount = lineItem.order_quantity
            console.log("Pump Count", pumpCount)
            let newLineItem = JSON.parse(JSON.stringify(lineItem))
            newLineItem.order_quantity = 1
            newLineItem.ship_quantity = 0
            // generate 4 digit random number
            // newLineItem.shipping_line_id = Math.floor(1000 + Math.random() * 9000)
            newLineItem.shipping_line_id = null
            while (pumpCount > 1) {
              this.shipmentDetail.ship_line_items.splice(index + 1, 0, newLineItem)
              pumpCount--
            }
            lineItem.order_quantity = 1
            lineItem.ship_quantity = 0
          }
        })
        this.$store.dispatch("common/showLoader", false)
      },

      openAddPump(item, index) {
        console.log("Get Pump", item, index)
        this.editLine = JSON.parse(JSON.stringify(item))
        this.searchPumpKeyword = ""
        this.searchPumpResults = []
        this.shipLineIndex = index
        this.shipLineItemId = item.shipping_line_id
        this.showPumpSelectModal = true
      },

      async searchPumps() {
        await django_session
          .get("erp/pump/list/", {
            params: {
              search_pump_keyword: this.searchPumpKeyword,
              page_size: 0,
              hide_inactive: true,
            },
          })
          .then((response) => {
            this.searchPumpResults = response.data
          })
      },

      selectPump(item) {
        console.log("Select Pump", item, this.shipLineIndex)
        this.editLine.shipping_pump = item.pump_id
        this.editLine.serial_number = item.serial_no
        this.editLine.ship_quantity = 1
        // this.shipmentDetail.ship_line_items[this.shipLineIndex].shipping_pump = item.pump_id
        // this.shipmentDetail.ship_line_items[this.shipLineIndex].serial_number = item.serial_no
        // this.shipmentDetail.ship_line_items[this.shipLineIndex].ship_quantity = 1
        this.shipmentDetail.ship_line_items.splice(this.shipLineIndex, 1, this.editLine)
        this.searchPumpKeyword = ""
        this.searchPumpResults = []
        // this.shipLineIndex = null
        // this.shipLineItemId = null
        this.showPumpSelectModal = false
      },

      async saveShipmentRecord() {
        this.$store.dispatch("common/showLoader", true)
        await django_session
          .patch("erp/service/ship/" + this.shipmentId + "/", this.shipmentDetail)
          .then((response) => {
            this.shipmentDetail = response.data
            this.$store.dispatch("common/showLoader", false)
            this.saveAlertMessage = "Shipment record saved."
            this.saveAlertVariant = "success"
            this.saveAlertTitle = "Success"
            this.showSaveRequestAlert = true
          })
          .catch((error) => {
            this.$store.dispatch("common/showLoader", false)
            console.log(error.response)
            this.saveAlertMessage = "Error saving shipment record."
            this.saveAlertVariant = "warning"
            this.saveAlertTitle = "Error"
            this.showSaveRequestAlert = true
          })
      },

      async saveNote() {
        this.requestNoteSaveComplete = false
        this.requestNoteIsSaving = true
        await django_session
          .post("erp/service/note/create/", {
            note: this.addNoteContent,
            request: this.shipmentDetail.service_request,
            created_by: this.user.user_id,
            modified_by: this.user.user_id,
          })
          .then((response) => {
            this.shipmentDetail.service_request_notes.unshift(response.data)
            this.saveNoteAlertVariant = "success"
            this.saveNoteAlertMessage = "Note saved to service record."
          })
          .catch((error) => {
            console.log(error.response)
            this.saveNoteAlertVariant = "warning"
            this.saveNoteAlertMessage = "Error saving note."
          })
        this.requestNoteIsSaving = false
        this.requestNoteSaveComplete = true
        this.showSaveNoteAlert = true
      },

      closeNoteModal() {
        this.showNoteModal = false
        this.addNoteContent = ""
        this.requestNoteSaveComplete = false
        this.showSaveNoteAlert = false
        this.saveNoteAlertMessage = ""
        this.saveNoteAlertVariant = ""
      },

      async generatePackingSlip() {
        await this.saveShipmentRecord()
        window.open(baseDomain + "erp/service/shipPackingSlip?shipId=" + this.shipmentDetail.shipment_id, "_blank")
      },
    },
  }
</script>
