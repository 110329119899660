<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Service Home" />

      <form @submit.prevent="getRequestList">
        <b-row class="mb-4 align-items-end">
          <b-colxx
            md="6"
            sm="12">
            <m-text-input
              id="search-request"
              label="Search"
              :value="searchKeyword"
              :alternative="false"
              :group="false"
              :readonly="false"
              name="Search"
              placeholder="Search by contact name or request number"
              @update-value="
                (input) => {
                  searchKeyword = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="4"
            sm="12">
            <b-button
              variant="info"
              class="ml-1"
              @click="getRequestList()"
              >Search</b-button
            >
            <b-button
              v-if="permissions.includes('CanCreateServiceRequest')"
              variant="success"
              @click="createNewRequest()"
              >New</b-button
            >
            <b-button
              variant="primary"
              class="float-right"
              @click="clearSearchList()"
              >Clear</b-button
            >
          </b-colxx>
          <b-colxx
            md="2"
            sm="12">
            <b-form-group
              id="hide-closed-fieldset"
              label="Hide Closed"
              label-for="hide-closed-yn"
              class="field-label-form-group mt-2">
              <b-form-checkbox
                id="hide-closed-yn"
                v-model="hideClosedYN"
                @change="() => {
                  current_page = 1
                  getRequestList()
                }"
                ></b-form-checkbox>
            </b-form-group>
          </b-colxx>
        </b-row>
      </form>

      <b-row class="my-4">
        <b-colxx
          md="12"
          sm="12">
          <b-table
            id="service-request-table"
            responsive
            head-variant="dark"
            :items="serviceRequestList"
            :fields="service_request_fields">
            <template #cell(view)="row">
              <i
                v-if="permissions.includes('CanViewServiceRequests')"
                class="fas fa-eye text-default icon-pointer"
                @click="goServiceRequestDetail(row.item, row.index)" />
            </template>
          </b-table>
          <b-pagination
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            :number-of-pages="pageTotal"
            size="sm"
            class="my-0"
            aria-controls="service-request-table"
            @input="getRequestList(current_page)"></b-pagination>
        </b-colxx>
      </b-row>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import { province } from "@/constants/province"
  import { rgaObj } from "@/constants/objects/rga"

  export default {
    name: "ServiceHome",
    components: {},

    data() {
      return {
        province,
        rgaObj,

        searchKeyword: "",
        hideClosedYN: true,
        serviceRequestList: [],
        service_request_fields: [
          {
            key: "view",
            label: "",
          },
          {
            key: "rga_number",
            label: "RGA #",
          },
          {
            key: "status_label",
            label: "Status",
          },
          {
            key: "full_name",
            label: "Name",
          },
          {
            key: "email_address",
            label: "Email",
          },
          {
            key: "service_request_type_name",
            label: "Request Type",
          },
          {
            key: "phone",
            label: "Phone",
          },
          {
            key: "customer_name",
            label: "Customer Name",
          },
        ],

        current_page: 1,
        per_page: 10,
        total_rows: null,
        prev_page_link: "",
        next_page_link: "",
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader", "serviceRequestTypeCodes"]),

      pageTotal() {
        var page_total = 1
        page_total = Math.ceil(this.total_rows / this.per_page)
        return page_total
      },
    },

    created() {},

    async mounted() {
      this.$store.dispatch("common/showLoader", true)
      document.title = "Service Requests"
      this.getRequestList()

      await django_session
        .get("erp/service/rga/list/", {
          params: {
            page: this.current_page,
            search_keyword: this.searchKeyword,
            hide_closed: this.hideClosedYN,
          },
        })
        .then((response) => {
          console.log(response.data)
          this.serviceRequestList = response.data.results
          this.total_rows = response.data.count
          this.prev_page_link = response.data.prev_page_link
          this.next_page_link = response.data.next_page_link
          this.$store.dispatch("common/showLoader", false)
        })
        .catch((error) => {
          this.$store.dispatch("common/showLoader", false)
        })
    },

    methods: {
      async getRequestList() {
        this.$store.dispatch("common/showLoader", true)
        await django_session
          .get("erp/service/rga/list/", {
            params: {
              search_keyword: this.searchKeyword,
              page: this.current_page,
              hide_closed: this.hideClosedYN,
            },
          })
          .then((response) => {
            console.log(response.data)
            this.serviceRequestList = response.data.results
            this.total_rows = response.data.count
            this.prev_page_link = response.data.prev_page_link
            this.next_page_link = response.data.next_page_link
            // this.$store.dispatch("common/showLoader", false)
          })
          .catch((error) => {
            // this.$store.dispatch("common/showLoader", false)
          })
        this.$store.dispatch("common/showLoader", false)
      },

      clearSearchList() {
        this.searchKeyword = ""
        this.current_page = 1
        this.getRequestList()
      },

      createNewRequest() {
        this.$store.dispatch("service/setNewRequestFlag", true)
        this.$router.push({ name: "RequestDetail" })
      },

      goServiceRequestDetail(item, index) {
        console.log(item, index)
        this.$store.dispatch("service/setNewRequestFlag", false)
        this.$store.dispatch("service/setServiceRequestId", item.service_request_id)
        this.$router.push({ name: "RequestDetail" })
      },
    },
  }
</script>
