export const rgaObj = {
  service_request_id: null,
  rga_number: "",
  contact_id: null,
  customer_id: null,
  customer_name: "",
  service_request_type: null,
  proof_of_purchase_url: "",
  cc_number: "",
  cc_expiry: "",
  created_by: null,
  created_at: "",
  modified_by: null,
  modified_at: null,
  note: "",
  first_name: "",
  last_name: "",
  email_address: "",
  phone: "",
  address_1: "",
  address_2: "",
  city: "",
  province: "",
  postal_code: "",
  select_pump_type: "",
  receive_line_items: [],
  shipment_line_items: [],
  service_request_status: null,
  shipping_notes: "",
  receiving_notes: "",
  reason_code: null,
}