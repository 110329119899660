<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Receiving Detail" />
      <b-alert
        id="save-request-toast"
        v-model="showSaveRequestAlert"
        class="w-100"
        dismissible
        :title="saveAlertTitle"
        :variant="saveAlertVariant">
        {{ saveAlertMessage }}
      </b-alert>
      <div class="full-height-row card">
      <b-form-row class="align-items-start">
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="rga-no"
            label="RGA Number"
            :value="receivingDetail.service_request_number"
            :alternative="false"
            :group="false"
            :disabled="true"
            :readonly="true"
            name="RGA Number" />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="contact-name"
            label="Contact Name"
            :rules="{ required: true, max: 50 }"
            :value="receivingDetail.contact_name"
            :alternative="false"
            :group="false"
            :readonly="false"
            :disabled="!permissions.includes('CanEditReceivingRecord')"
            name="Contact Name"
            @update-value="
              (input) => {
                receivingDetail.contact_name = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="customer-name"
            label="Customer Name"
            :rules="{ max: 50 }"
            :value="receivingDetail.customer_name"
            :alternative="false"
            :group="false"
            :readonly="false"
            :disabled="!permissions.includes('CanEditReceivingRecord')"
            name="Custome Namer"
            @update-value="
              (input) => {
                receivingDetail.customer_name = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="phone"
            label="Phone"
            type="phone"
            :rules="{ required: true, max: 50 }"
            :value="receivingDetail.phone"
            :alternative="false"
            :group="false"
            name="Phone"
            :readonly="false"
            :disabled="!permissions.includes('CanEditReceivingRecord')"
            @update-value="
              (input) => {
                receivingDetail.phone = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="email"
            label="Email"
            :rules="{ required: true, email: true }"
            :value="receivingDetail.email_address"
            :alternative="false"
            :group="false"
            name="Email"
            :readonly="false"
            :disabled="!permissions.includes('CanEditReceivingRecord')"
            @update-value="
              (input) => {
                receivingDetail.email_address = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="address-1"
            label="Address 1"
            :rules="{ required: true, max: 100 }"
            :value="receivingDetail.address_1"
            :alternative="false"
            :group="false"
            name="Address 1"
            :readonly="false"
            :disabled="!permissions.includes('CanEditReceivingRecord')"
            @update-value="
              (input) => {
                receivingDetail.address_1 = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="address-2"
            label="Address 2"
            :rules="{ max: 100 }"
            :value="receivingDetail.address_2"
            :alternative="false"
            :group="false"
            name="Address 2"
            :readonly="false"
            :disabled="!permissions.includes('CanEditReceivingRecord')"
            @update-value="
              (input) => {
                receivingDetail.address_2 = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="city"
            label="City"
            :rules="{ required: true, max: 75 }"
            :value="receivingDetail.city"
            :alternative="false"
            :group="false"
            name="City"
            :readonly="false"
            :disabled="!permissions.includes('CanEditReceivingRecord')"
            @update-value="
              (input) => {
                receivingDetail.city = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="2"
          sm="12">
          <b-form-group
            id="prov-type-fieldset"
            label="Province"
            label-for="prov-select"
            class="field-label-form-group mt-2">
            <b-form-select
              id="prov-select"
              v-model="receivingDetail.province"
              :disabled="!permissions.includes('CanEditReceivingRecord')"
              :options="province"></b-form-select>
          </b-form-group>
        </b-colxx>
        <b-colxx
          md="2"
          sm="12">
          <m-text-input
            id="postalcode"
            label="Postal Code"
            :rules="{ required: true, max: 7 }"
            :value="receivingDetail.postal_code"
            :alternative="false"
            :group="false"
            name="Postal Code"
            :readonly="false"
            :disabled="!permissions.includes('CanEditReceivingRecord')"
            @update-value="
              (input) => {
                receivingDetail.postal_code = input
              }
            " />
        </b-colxx>
      </b-form-row>
      <b-form-row>
        <b-colxx
          md="3"
          sm="12">
          <b-form-group
            id="request-status-fieldset"
            label="Status *"
            label-for="request-status"
            class="field-label-form-group mt-2">
            <b-form-select
              id="request-status"
              v-model="receivingDetail.receiving_status"
              text-field="type_label"
              value-field="entity_type_code_id"
              :disabled="!permissions.includes('CanEditReceivingRecord')"
              :options="requestStatusCodes"></b-form-select>
          </b-form-group>
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <m-text-input
            id="inbound-tracking"
            label="Inbound Tracking #"
            :value="receivingDetail.shipment_tracking_number"
            :rules="{}"
            :alternative="false"
            :group="false"
            name="Inbound Tracking #"
            :disabled="!permissions.includes('CanEditReceivingRecord')"
            @update-value="
              (input) => {
                receivingDetail.shipment_tracking_number = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="3"
          sm="12">
          <b-form-group
            id="request-status-fieldset"
            label="Shipper *"
            label-for="request-status"
            class="field-label-form-group mt-2">
            <b-form-select
              id="request-status"
              v-model="receivingDetail.shipper"
              text-field="shipper_name"
              value-field="shipper_id"
              :disabled="!permissions.includes('CanEditReceivingRecord')"
              :options="shipperList"></b-form-select>
          </b-form-group>
        </b-colxx>
      </b-form-row>

      <b-row class="mt-4">
        <b-colxx
          md="12"
          sm="12">
          <b-table
            id="shipment-lines-table"
            ref="__shipmentLineDetail"
            responsive
            head-variant="dark"
            :bordered="true"
            :items="receivingDetail.receive_line_items"
            :fields="receive_line_item_fields">
            <template #cell(edit)="row">
              <i
                v-if="row.item.line_item_is_posted_yn"
                class="fas fa-circle-check text-success"
              />
            </template>
            <template #cell(ship_quantity)="row">
              <b-form-input
                :id="'sq' + row.item.shipping_line_id"
                type="number"
                :disabled="row.item.line_item_is_posted_yn || row.item.line_item_is_pump_yn || !permissions.includes('CanEditReceivingRecord')"
                v-model="row.item.ship_quantity" />
            </template>
            <template #cell(serial_no)="row">
              <b-form-input
                v-if="row.item.shipping_pump !== null"
                :id="'serialno' + row.item.receiving_line_id"
                :readonly="true"
                v-model="row.item.receiving_pump_serial_number" />
              <b-button
                v-if="row.item.line_item_is_pump_yn && !row.item.line_item_is_posted_yn && permissions.includes('CanEditReceivingRecord')"
                variant="success"
                size="sm"
                @click="openSelectDepot(row.item, row.index)"
                >Receive Pump</b-button
              >
            </template>
          </b-table>
        </b-colxx>
      </b-row>
      <b-row class="my-4">
        <b-colxx md="12" sm="12">
          <m-text-area
            id="receiving-note"
            :value="receivingDetail.receiving_notes"
            name="Receiving Notes"
            :rows="7"
            :rules="{ max: 4000 }"
            label="Receiving Notes"
            :alternative="false"
            :group="false"
            :disabled="!permissions.includes('CanEditReceivingRecord')"
            @update-value="(input) => (receivingDetail.receiving_notes = input)" />
        </b-colxx>
      </b-row>

      <div id="service-request-notes">
        <b-form-row>
          <b-colxx
            md="12"
            sm="12">
            <b-table
        id="service-request-notes-table"
        responsive
        head-variant="dark"
        :items="receivingDetail.service_request_notes"
        :fields="service_request_notes_fields">
        <template #head(add_note)>
            <i
              v-if="permissions.includes('CanEditReceivingRecord')"
              class="fas fa-square-plus icon-pointer text-primary text-white"
              @click="
                () => {
                  showNoteModal = true
                }
              " />
        </template>
        <template #cell(created_at)="row">
          {{ new Date(row.item.created_at).toLocaleDateString() }}
        </template>
      </b-table>
          </b-colxx>
        </b-form-row>
      </div>
      </div>
      
      
      <b-row class="my-4">
        <b-colxx md="12" sm="12">
          <b-button
            v-if="permissions.includes('CanEditReceivingRecord')"
            variant="success"
            class="mr-2"
            :disabled="false"
            @click="saveReceivingRecord()"
            >Save</b-button
          >
          <!-- <b-button
            variant="info"
            class="mr-2"
            @click="generatePackingSlip()"
            >Generate Packing Slips</b-button
          > -->
        </b-colxx>
      </b-row>
    </div>

    <!-- Select Depot List Modal -->
    <div id="select-depot-list">
      <b-modal
        id="select-depot-list-modal"
        v-model="showDepotSelectModal"
        title="Select Depot for Pump"
        centered
        ok-only
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="md">
        <b-row>
          <b-colxx
            md="12"
            sm="12">
            <b-table
              id="pump-search-result-table"
              ref="__depotSelectTable"
              responsive
              head-variant="dark"
              :items="depotList"
              :fields="depot_list_fields">
              <template #cell(select)="row">
                <i
                  class="fas fa-circle-plus text-default icon-pointer"
                  @click="selectDepot(row.item)" />
              </template>
            </b-table>
          </b-colxx>
        </b-row>
      </b-modal>
    </div>

    <!-- New Note Modal -->
    <div id="create-new-note">
      <b-modal
        id="search-contact-list-modal"
        v-model="showNoteModal"
        title="Add a Note"
        centered
        ok-only
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="md">
        <m-spinny-message
          v-if="requestNoteIsSaving"
          message="Note is saving." />
        <b-alert
          v-model="showSaveNoteAlert"
          :variant="saveNoteAlertVariant"
          class="w-100"
          dismissible
          >{{ saveNoteAlertMessage }}</b-alert
        >
        <m-text-area
          v-if="!requestNoteIsSaving"
          id="note"
          :value="addNoteContent"
          name="Notes"
          :rows="7"
          :rules="{ max: 4000 }"
          label="Notes"
          :alternative="false"
          :group="false"
          @update-value="(input) => (addNoteContent = input)" />
        <template #modal-footer="{}">
          <b-button
            v-if="!requestNoteSaveComplete"
            variant="info"
            :disabled="requestNoteIsSaving"
            @click="saveNote()">
            Save
          </b-button>
          <b-button
            variant="warning"
            :disabled="requestNoteIsSaving"
            @click="closeNoteModal()"
            >Close</b-button
          >
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import { province } from "@/constants/province"

  export default {
    name: "ReceivingDetail",
    components: {},

    data() {
      return {
        province,
        receivingDetail: {},
        shipperList: [],

        editLine: null,
        editLineIndex: null,

        receive_line_item_fields: [
          {
            label: "",
            key: "edit",
          },
          {
            label: "Order Qty",
            key: "order_quantity",
          },
          {
            label: "Order Qty",
            key: "order_quantity",
          },
          {
            label: "Receive Qty",
            key: "ship_quantity",
          },
          {
            label: "Name",
            key: "product_description",
          },
          {
            label: "Move To Depot",
            key: "receiving_depot_name",
          },
          {
            label: "Serial #",
            key: "serial_no",
          },
        ],

        service_request_notes_fields: [
          {
            key: "add_note",
            label: "",
            thStyle: { width: "5%" },
          },
          {
            key: "created_at",
            label: "Date",
          },
          {
            key: "note",
            label: "Note",
          },
          {
            key: "created_by_name",
            label: "Author",
          },
        ],

        depotList: [],
        depot_list_fields: [
          {
            key: "select",
            label: "",
          },
          {
            key: "name",
            label: "Depot Name",
          },
        ],
        showDepotSelectModal: false,


        receiveLineIndex: null,
        receiveLineItemId: null,
        // searchPumpKeyword: "",
        // searchPumpResults: [],
        // search_pump_fields: [
        //   {
        //     label: "",
        //     key: "select",
        //   },
        //   {
        //     label: "Pump Type",
        //     key: "pump_type_name",
        //   },
        //   {
        //     label: "Serial #",
        //     key: "serial_no",
        //   },
        //   {
        //     label: "Leased",
        //     key: "is_leased_yn",
        //   },
        //   {
        //     label: "Purchase Date",
        //     key: "purchase_date",
        //   },
        // ],
        // showPumpSelectModal: false,
        showNoteModal: false,
        requestNoteIsSaving: false,
        requestNoteSaveComplete: false,
        addNoteContent: "",
        showSaveNoteAlert: false,
        saveNoteAlertVariant: "",
        saveNoteAlertMessage: "",

        saveAlertMessage: "",
        saveAlertVariant: "",
        saveAlertTitle: "",
        showSaveRequestAlert: false,
        
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader", "serviceRequestTypeCodes", "requestStatusCodes"]),
      ...mapGetters("service", ["receivingId"]),
    },

    created() {},

    async mounted() {
      this.getReceivingDetail()
      await django_session.get("erp/product/shipper/list/", {
        params: {
          page_size: 0,
        }
      }).then((response) => {
        this.shipperList = response.data
      })
    },

    methods: {
      async getReceivingDetail() {
        this.$store.dispatch("common/showLoader", true)
        await django_session
          .get("erp/service/receive/" + this.receivingId + "/")
          .then((response) => {
            this.receivingDetail = response.data
          })
          .catch((error) => {
            console.log(error.response)
          })
        this.$store.dispatch("common/showLoader", false)
      },

      async openSelectDepot(item, index) {
        console.log("Line Detail", item, index)
        this.receiveLineIndex = index
        this.receiveLineItemId = item.receiving_line_id
        await django_session.get("erp/pump/depot/list/", {
          params: {
            page_size: 0,
          }
        }).then((response) => {
          this.depotList = response.data
          this.showDepotSelectModal = true
        })
      },

      selectDepot(item) {
        let depotId = item.depot_id
        let depotName = item.name
        Object.assign(this.receivingDetail.receive_line_items[this.receiveLineIndex], {
          receiving_depot_id: depotId,
          receiving_depot_name: depotName,
        })
        this.receivingDetail.receive_line_items[this.receiveLineIndex].ship_quantity = this.receivingDetail.receive_line_items[this.receiveLineIndex].order_quantity
        this.receiveLineIndex = null
        this.receiveLineItemId = null
        this.showDepotSelectModal = false
      },

      saveReceivingRecord() {
        this.$store.dispatch("common/showLoader", true)
        django_session
          .patch("erp/service/receive/" + this.receivingId + "/", this.receivingDetail)
          .then((response) => {
            this.receivingDetail = response.data
            this.$store.dispatch("common/showLoader", false)
            this.saveAlertMessage = "Receiving record saved."
            this.saveAlertVariant = "success"
            this.saveAlertTitle = "Success"
            this.showSaveRequestAlert = true
            
          })
          .catch((error) => {
            this.$store.dispatch("common/showLoader", false)
            console.log(error.response)
            this.saveAlertMessage = "Error saving receiving record."
            this.saveAlertVariant = "warning"
            this.saveAlertTitle = "Error"
            this.showSaveRequestAlert = true
          })
      },

      async saveNote() {
        this.requestNoteSaveComplete = false
        this.requestNoteIsSaving = true
        await django_session
          .post("erp/service/note/create/", {
            note: this.addNoteContent,
            request: this.receivingDetail.service_request,
            created_by: this.user.user_id,
            modified_by: this.user.user_id,
          })
          .then((response) => {
            this.receivingDetail.service_request_notes.unshift(response.data)
            this.saveNoteAlertVariant = "success"
            this.saveNoteAlertMessage = "Note saved to service record."
          })
          .catch((error) => {
            console.log(error.response)
            this.saveNoteAlertVariant = "warning"
            this.saveNoteAlertMessage = "Error saving note."
          })
        this.requestNoteIsSaving = false
        this.requestNoteSaveComplete = true
        this.showSaveNoteAlert = true
      },

      closeNoteModal() {
        this.showNoteModal = false
        this.addNoteContent = ""
        this.requestNoteSaveComplete = false
        this.showSaveNoteAlert = false
        this.saveNoteAlertMessage = ""
        this.saveNoteAlertVariant = ""
      },
    },
  }
</script>
